/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
// react component for creating dynamic tables
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { currencyIDR } from "services/currency";

// reactstrap components
import { Card, CardHeader, Alert} from "reactstrap";

const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={(e) => onSizePerPageChange(e.target.value)}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        }{" "}
      </label>
    </div>
  ),
});

const TabInvoiceAmount = (props) => {
  const info = props.info;
  return (
    <>
      <Card>
        <ToolkitProvider
          data={props.list}
          keyField="invoice"
          striped
          hover
          condensed
          columns={[
            {
              dataField: "created_at",
              text: "Date",
              headerAlign:"center",
              align:"center",
              sort: true,
            },
            {
              dataField: "company_name",
              text: "Vendor",
              sort: true,
            },
            {
              dataField: "invoice",
              text: "Invoice",
              sort: true,
            },
            {
              dataField: "purchase_order",
              text: "PO",
              headerAlign:"center",
              align:"center",
              sort: true,
            },
            {
              dataField: "good_receipt",
              text: "GR",
              headerAlign:"center",
              align:"center",
              sort: true,
            },
            {
              dataField: "invoice_amount",
              text: "Amount",
              headerAlign:"right",
              align:"right",
              sort: true,
            },
            {
              dataField: "pic",
              text: "pic",
              sort: true,
            },
            {
              dataField: "revision",
              text: "Rev",
              headerAlign:"center",
              align:"center",
              sort: true,
            },
          ]}
        >
          {(props) => (
            <div>
              <CardHeader>
                  {info}
              </CardHeader>
              <BootstrapTable
                {...props.baseProps}
                bootstrap4={true}
                pagination={pagination}
                bordered={false}
                wrapperClasses="table-responsive"
                striped
                hover
                noDataIndication={() => (
                  <div style={{ height: 300 }}>
                    <Alert style={{ marginLeft: -30 }} color="secondary">
                      <strong>Data not found!</strong> Please check again!
                    </Alert>
                  </div>
                )}
              />
            </div>
          )}
        </ToolkitProvider>
      </Card>
    </>
  );
};

export default TabInvoiceAmount;
