import { roles } from "services/roles";
import Recovery from "views/Auth/Recovery";
import Verify from "views/Auth/Verify";
import Login from "views/Auth/Login";
// import LoginV2 from "views/Auth/LoginV2";
import LoginHospital from "views/Auth/LoginHospital";
import Home from "views/Auth/Home";
import LoginForwarder from "views/Auth/Forwarder";
import LoginAp from "views/Auth/Ap";
import LoginTax from "views/Auth/Tax";
import CircularLetter from "views/Regulation/Letter/CircularLetter";
// import Home from "views/Auth/Home";
import Template from "views/pages/dashboards/Dashboard";


//VENDOR
import DashboardVendor from "views/Vendor/DashboardVendor";
import VendorListNotification from "views/Vendor/Notification/VendorNotification";
import VendorInvoiceRegulerCreate from "views/Vendor/InvoiceReguler/CreateReguler/VendorCreateRegulerInvoicev2";
import VendorInvoiceRegulerUpdate from "views/Vendor/InvoiceReguler/CreateReguler/VendorUpdateRegulerInvoicev2";
import VendorInvoiceReguler from "views/Vendor/InvoiceReguler/VendorInvoiceReguler";
import VendorInvoiceRegulerReview from "views/Vendor/InvoiceReguler/VendorReviewRegulerInvoice";
import VendorInvoiceRegulerAll from "views/Vendor/InvoiceReguler/VendorInvoiceAll";
import VendorTax from "views/Vendor/Slip/TaxSlip";
import VendorSettings from "views/Vendor/Settings/Settings";
import VendorSettingsUpdate from "views/Vendor/Settings/SettingsUpdate";
import ListCompanies from "views/Admin/Companies/Companies";
import CompanyDetails from "views/Admin/Companies/CompanyDetails";
import User from "views/Admin/User/Users";
// import UserDetails from "views/Admin/User/UserDetails";

// ADMIN
import DashboardAPAadmin from "views/Admin/DashboardAPadmin";
import MetricInvoiceTracking from "views/Admin/MetricInvoiceVendor";
import MetricInvoiceAmount from "views/Admin/MetricInvoiceAmount";
import MetricInvoicePic from "views/Admin/Task/ListTask";
import ListInvoiceOutstandingAdmin from "views/Admin/ListInvoiceOutstanding";
import ListInvoiceReceivedAdmin from "views/Admin/ListInvoiceReceived";
import InvoiceRoutingSlipAdmin from "views/Admin/InvoiceRoutingSlipAdmin";

import VendorAdmin from "views/Admin/Companies/AdminVendor/VendorAdmin";
import VendorDetailAdmin from "views/Admin/Companies/AdminVendor/VendorDetailAdmin";

import VendorInvoiceDetailAdmin from "views/Admin/AdminInvoiceDetail";
import UserAdmin from "views/Admin/User/AdminVendor/UsersAdmin";
import SettingAdmin from "views/Admin/SettingAdm";

// TAX
import DashboardTAX from "views/Tax/DashboardTAX";
import InvoiceTax from "views/Tax/InvoiceTax";
// import TaxInvoiceVerification from "views/Tax/TaxInvoiceValidationv2";
import InvoiceTaxAll from "views/Tax/InvoiceTaxAll";
import SettingSlip from "views/Tax/setting/SettingBlastInvoice";
import SettingTax from "views/Tax/setting/SettingTax";
import InvoiceTaxDetail from "views/Tax/TaxInvoiceDetail";
import ProfileTax from "views/Tax/ProfileTax";
// import ProfileAP from "views/Admin/Invoice/AP/ProfileAP";

// AP
import RoutingSlip from "views/AP/form/InvoiceRoutingSlip";
import ApInvoiceValidation from "views/AP/form/ApInvoiceValidationV2";
import ApInvoiceValidationV3 from "views/AP/form/ApInvoiceValidationV3";
import InvoiceStandardAp from "views/AP/InvoiceStandardAp";
import AllInvoiceStandardAp from "views/AP/AllInvoiceStandardAp";
import ApInvoiceValidationReview from "views/AP/form/ApInvoiceReview";
import DashboardAP from "views/AP/DashboardAP";
import Registration from "views/Auth/Registration";
// import RecoveryAP from "views/Auth/RecoveryAccount";

// import MyProfileVendor from "views/pages/components/Icons";
import MyProfileVendor from "views/Vendor/MyProfile/MyProfileVendor";


import DashboardForwarder from "views/Forwarder/ForwarderVendorList";
import DetailInvoiceForwarder from "views/Forwarder/ForwarderVendorDetail";


/// Hospital///
import DashboardHospital from "views/Hospital/DashboardHospital";

const routes = [


  
  
  // ======== FORWADER =========
  {
    path: "/list-invoice-forwarder",
    name: "Invoice",
    icon: "fa fa-file-invoice text-dark",
    component: DashboardForwarder,
    role: roles(8),
    layout: "/forwarder",
  },
  {
    path: "/detail-invoice-forwarder/:id",
    component: DetailInvoiceForwarder,
    role: roles(8),
    layout: "/forwarder",
    child: false,
  },
  // ======== END FORWADER =========
  
  // ======== TAX =========
  {
    path: "/dashboards",
    name: "Dashboard",
    icon: "fa fa-pie-chart text-dark",
    component: DashboardTAX,
    role: roles(3),
    layout: "/tax",
  },
  {
    name: "Invoice",
    icon: "fa fa-file-invoice text-dark",
    state: "Invoice-taxCollapse",
    role: roles(3),
    layout: "/tax",
    collapse: true,
    views: [
      {
        path: "/active-invoice-tax",
        name: "open",
        miniName: "act",
        component: InvoiceTax,
        role: roles(3),
        layout: "/tax",
      },
      {
        path: "/detail-invoice-tax/:id/:fr",
        component: InvoiceTaxDetail,
        role: roles(3),
        layout: "/tax",
        child: false,
      },
      // {
      //   path: "/tax_verification/:InvoiceId/:opt",
      //   name: "Tax verification",
      //   component: TaxInvoiceVerification,
      //   role: roles(3),
      //   layout: "/tax",
      //   child: false,
      // },
      {
        path: "/all-invoice-tax/:tb",
        name: "close",
        miniName: "cls",
        component: InvoiceTaxAll,
        role: roles(3),
        layout: "/tax",
      },
    ],
  },
  {
    name: "Setting",
    icon: "fa fa-cogs text-dark",
    role: roles(3),
    layout: "/tax",
    collapse: true,
    views:[
      {
        path: "/blast-slip",
        name: "tax slip",
        miniName: "ts",
        component: SettingSlip,
        role: roles(3),
        layout: "/tax",
      },
      {
        path: "/settings-tax",
        name: "pph",
        miniName: "pph",
        component: SettingTax,
        role: roles(3),
        layout: "/tax",
      },
    ]
  },
  {
    path: "/profile-tax",
    name: "Profile",
    icon: "fa fa-user text-dark",
    component: ProfileTax,
    role: roles(3),
    layout: "/tax",
  },
  // ========= END TAX =========
    // ========= AP =========
    {
      path: "/dashboards",
      name: "Dashboard",
      icon: "fa fa-pie-chart text-dark",
      component: DashboardAP,
      role: roles(2),
      layout: "/ap",
      // child: false
    },
    {
      name: "Invoice",
      icon: "fa fa-file-invoice text-dark",
      state: "apreg InvoiceCollapse",
      role: roles(2),
      layout: "/ap",
      collapse: true,
      views: [
        {
          path: "/reguler-invoice",
          name: "open",
          miniName: "opn",
          component: InvoiceStandardAp,
          role: roles(2),
          layout: "/ap",
        },
        {
          path: "/all-reguler-invoice/:tb",
          name: "close",
          miniName: "cls",
          component: AllInvoiceStandardAp,
          role: roles(2),
          layout: "/ap",
        },
        {
          path: "/invoice-routing-slip/:conId",
          name: "Invoice routing slip",
          icon: "ni ni-building text-dark",
          component: RoutingSlip,
          role: roles(2),
          layout: "/ap",
          child: false,
        },
        // {
        //   path: "/print-routing-slip/:conId",
        //   name: "Print routing slip",
        //   icon: "ni ni-building text-dark",
        //   component: RoutingSlipPrint,
        //   role: roles(2),
        //   layout: "/ap",
        //   child: false,
        // },
        {
          path: "/ap_verificationV2/:id",
          name: "Ap Verification",
          component: ApInvoiceValidation,
          layout: "/ap",
          role: roles(2),
          child: false,
        },
        {
          path: "/ap_verificationV3/:id",
          name: "Ap Verification",
          component: ApInvoiceValidationV3,
          layout: "/ap",
          role: roles(2),
          child: false,
        },
        // {
        //   path: "/ap_review_verification/:id",
        //   name: "Ap Review Verification",
        //   component: ApInvoiceValidationReject,
        //   layout: "/ap",
        //   role: roles(2),
        //   child: false,
        // },
        {
          path: "/ap_review_invoice/:id/:tb",
          name: "Ap Review Verification",
          component: ApInvoiceValidationReview,
          layout: "/ap",
          role: roles(2),
          child: false,
        },
      ],
    },
    {
      path: "/vendors",
      name: "Vendor",
      icon: "ni ni-building text-dark",
      component: ListCompanies,
      role: roles(2),
      layout: "/ap",
    },
    // {
    //   path: "/add-vendor",
    //   name: "Add vendor",
    //   component: CreateCompany,
    //   layout: "/ap",
    //   role: roles(2),
    //   child: false,
    // },
    {
      path: "/vendor_details/:companyId/:opt",
      name: "Vendor Details",
      component: CompanyDetails,
      role: roles(2),
      layout: "/ap",
      child: false,
    },
    {
      path: "/users",
      name: "User",
      icon: "fa fa-users text-dark",
      component: User,
      role: roles(2),
      layout: "/ap",
    },
    // {
    //   path: "/profile-ap",
    //   name: "Profile",
    //   icon: "fa fa-user text-dark",
    //   component: ProfileAP,
    //   role: roles(2),
    //   layout: "/ap",
    //   // child: false
    // },
    // {
    //   path: "/user_details/:userId",
    //   name: "User Details",
    //   component: UserDetails,
    //   layout: "/ap",
    //   role: roles(2),
    //   child: false,
    // },
    // {
    //   path: "/settings-ap",
    //   name: "Settings",
    //   icon: "fa fa-cogs text-dark",
    //   component: SettingTax,
    //   role: roles(2),
    //   layout: "/ap",
    // },
  
  
  //================================ VENDOR ============================================
  {
      icon: "ni ni-chart-pie-35 text-dark",
      path: "/home",
      name: "Dashboard",
      component: DashboardVendor,
      role: roles(6),
      layout: "/vendor"
    },
    {
      name: "Invoice",
      icon: "fa fa-file-invoice text-dark",
      state: "vendor-regulerCollapse",
      role: roles(6),
      collapse: true,
      layout: "/vendor",
      views: [
        {
          path: "/update-reguler-invoice/:opt/:invoiceId",
          name: "update reguler invoice",
          component: VendorInvoiceRegulerUpdate,
          role: roles(6),
          child: false,
          layout: "/vendor",
        },
        {
          path: "/add-reguler-invoice",
          name: "add reguler invoice",
          component: VendorInvoiceRegulerCreate,
          role: roles(6),
          child: false,
          layout: "/vendor",
        },
        {
          path: "/review-reguler-invoice/:opt/:id",
          name: "review reguler invoice",
          component: VendorInvoiceRegulerReview,
          role: roles(6),
          layout: "/vendor",
          child: false,
        },
        {
          path: "/new-notification-vendor/:opt",
          // name: "new-notification-vendor",
          component: VendorListNotification,
          role: roles(6),
          layout: "/vendor",
          child: false,
        },
        {
          path: "/active-reguler-invoice",
          name: "open",
          miniName: "o",
          component: VendorInvoiceReguler,
          role: roles(6),
          layout: "/vendor",
        },
        {
          path: "/close-reguler-invoice/:tb",
          name: "close",
          miniName: "c",
          component: VendorInvoiceRegulerAll,
          role: roles(6),
          layout: "/vendor",
        },
      ],
    },
    {
      icon: "fa fa-folder text-dark",
      path: "/taxslip-vendor",
      name: "Tax Slip",
      miniName: "st",
      component: VendorTax,
      role: roles(6),
      layout: "/vendor",
    },
    {
      icon: "fa fa-cog text-dark",
      path: "/settings",
      name: "Setting",
      miniName: "st",
      component: VendorSettings,
      role: roles(6),
      layout: "/vendor",
    },
    {
      path: "/settings-edit",
      miniName: "ste",
      component: VendorSettingsUpdate,
      role: roles(6),
      layout: "/vendor",
      child: false,
    },
    {
      icon: "fa fa-user text-dark",
      path: "/profile",
      name: "Profile",
      miniName: "MP",
      component: MyProfileVendor,
      role: roles(6),
      layout: "/vendor",
    },
//////

  // ======== ADMIN =========
  // {
  //   path: "/dashboard",
  //   name: "Dashboard",
  //   icon: "ni ni-building text-dark",
  //   component: Dashboard,
  //   role: roles(1),
  //   layout: "/admin",
  // },
  // {
  //   path: "/xa",
  //   name: "example",
  //   icon: "ni ni-building text-dark",
  //   component: Template,
  //   role: roles(7),
  //   layout: "/admin",
  // },
  {
    path: "/dashboards",
    name: "Dashboard",
    icon: "fa fa-pie-chart text-dark",
    component: DashboardAPAadmin,
    role: roles(7),
    layout: "/admin",
  },
  {
    path: "/invoice-pic",
    name: "PIC",
    icon: "ni ni-badge text-dark",
    // state: "adtask",
    role: roles(7),
    component: MetricInvoicePic,
    layout: "/admin",
  },
  {
    path: "/invoice-amount",
    name: "Invoice Amount ",
    icon: "fa fa-file-invoice text-dark",
    role: roles(7),
    component: MetricInvoiceAmount,
    layout: "/admin",
  },
  // {
  //   name: "Invoice Document",
  //   icon: "fa fa-file-text text-dark",
  //   collapse: true,
  //   state: "invoice-document",
  //   role: roles(7),
  //   layout: "/admin",
  //   views:[
  //     {
  //       path: "/outstanding-document",
  //       name: "outstanding",
  //       miniName: "o",
  //       component: ListInvoiceOutstandingAdmin,
  //       role: roles(7),
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/received-document",
  //       name: "received",
  //       miniName: "r",
  //       component: ListInvoiceReceivedAdmin,
  //       role: roles(7),
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/vendor_invoice_detail/:id",
  //       name: "Vendor Details",
  //       component: VendorInvoiceDetailAdmin,
  //       role: roles(7),
  //       layout: "/admin",
  //       child: false,
  //     },
  //     {
  //       path: "/invoice-routing-slip-admin/:conId",
  //       name: "Invoice routing slip Admin",
  //       icon: "ni ni-building text-dark",
  //       component: InvoiceRoutingSlipAdmin,
  //       role: roles(7),
  //       layout: "/admin",
  //       child: false,
  //     },
  //   ]
  // },
  {
    path: "/invoice-summary",
    name: "Invoice Summary",
    icon: "fa fa-bookmark text-dark",
    role: roles(7),
    component: MetricInvoiceTracking,
    layout: "/admin",
  },
  {
    path: "/vendor",
    name: "Vendor",
    icon: "ni ni-building text-dark",
    component: VendorAdmin,
    role: roles(7),
    layout: "/admin",
  },
  {
    path: "/vendor_detail/:companyId",
    name: "Vendor Details",
    component: VendorDetailAdmin,
    role: roles(7),
    layout: "/admin",
    child: false,
  },
  {
    path: "/user",
    name: "User",
    icon: "fa fa-users text-dark",
    component: UserAdmin,
    role: roles(7),
    layout: "/admin",
  },
  {
    path: "/settings",
    name: "Setting",
    icon: "fa fa-cog text-dark",
    component: SettingAdmin,
    role: roles(7),
    layout: "/admin",
  },
  // {
  //   path: "/user_details/:userId",
  //   name: "User Details",
  //   component: UserDetails,
  //   layout: "/admin",
  //   role: roles(7),
  //   child: false,
  // },
  // {
  //   path: "/user_details/:userId",
  //   name: "User Details",
  //   component: UserDetails,
  //   layout: "/admin",
  //   role: roles(7),
  //   child: false,
  // },
  {
    path: "/template",
    name: "Template",
    icon: "ni ni-building text-dark",
    component: Template,
    role: roles(1),
    layout: "/admin",
  },
 //================================ VENDOR ============================================
 {
  icon: "ni ni-chart-pie-35 text-dark",
  path: "/home-hospital",
  name: "Dashboard",
  component: DashboardHospital,
  role: roles(9),
  layout: "/hospital"
},
  //auth
  {
    path: "/login",
    name: "Login",
    miniName: "L",
    component: Login,
    layout: "/auth",
    child: false,
  },
  // {
  //   path: "/loginV2",
  //   name: "Login",
  //   miniName: "L",
  //   component: LoginV2,
  //   layout: "/auth",
  //   child: false,
  // },
  {
    path: "/recovery",
    name: "Recovery",
    miniName: "R",
    component: Recovery,
    layout: "/auth",
    child: false,
  },
  {
    path: "/register",
    name: "Create New Account",
    miniName: "C",
    component: Registration,
    layout: "/auth",
    child: false,
  },
  {
    path: "/recovery-account",
    name: "Recovery Account",
    miniName: "C",
    component: Recovery,
    layout: "/auth",
    child: false,
  },
  {
    path: "/verify/:id",
    name: "invitation-register",
    miniName: "C",
    component: Verify,
    layout: "/auth",
    child: false,
  },
  {
    path: "/home",
    name: "Login admin",
    miniName: "LGADM",
    component: Home,
    layout: "/auth",
    child: false,
  },
  {
    path: "/loginhospital",
    component: LoginHospital,
    name: "Login HC",
    layout: "/auth2",
    child: false,
  },
  {
    path: "/forwarder",
    name: "Login Forwarder",
    component: LoginForwarder,
    layout: "/auth",
    child: false,
  },
  {
    path: "/ap",
    name: "Login ap",
    component: LoginAp,
    layout: "/auth",
    child: false,
  },
  {
    path: "/tax",
    name: "Login tax",
    component: LoginTax,
    layout: "/auth",
    child: false,
  },
  {
    path: "/circular-letter",
    name: "Circular letter",
    component: CircularLetter,
    layout: "/regulation",
    child: false,
  },
];

export default routes;
